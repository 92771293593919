crminfo = require('./crminfo.staging').default

crminfo.enableTags = true
crminfo.isGeekly = true
crminfo.apiHostSmsTool = 'https://gh-connect-stage.dsh-agency.com'
crminfo.baseUrlWFH = 'https://connect-stage.dsh-agency.com'
crminfo.templateMessages = 'templateMessages.geekly';
crminfo.showOfferSubscription = false;

defaultConfig = require('./default-config')({
  domain: 'gh-connect-stage.dsh-agency.com',
  protocol: 'https',
  sentry: false
  updateNotification: true
  crmUrl: 'https://a4.dsh-agency.com/api/v1/crminfo'
  crmUrlA5: 'https://api-stage.tn-company.com/api/v3'
  GMTOffset: '+0300'
  crmPluginEnabled: true
  oauthEnabled: false
  authApiHost: 'https://oauth-stage.dsh-agency.com'
  authBackApiHost: 'https://auth-back-stage.dsh-agency.com'
  fileServerUrl: 'https://n4bybpe0o7.execute-api.eu-west-1.amazonaws.com/dev'
  mqttUrl: 'wss://gh-connect-stage.dsh-agency.com:8084/mqtt'
  crminfo: crminfo
  dashboardTags: false
  analyticByChannelType: true
  xmpp: false
  isGeekly: true
  showRankingRules: true
  showTotalActiveConversationsSales: false
  showUnreadMessages: true
})

module.exports = _.extend defaultConfig, {}
